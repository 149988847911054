import React from 'react';
import './style.css';

function Footer() {
  return (
    <div className="footer">
        @2020 Inspiration<a rel="noopener noreferrer" href="http://beian.miit.gov.cn" target="_blank">闽ICP备2020017631号</a>
    </div>
  );
}

export default Footer;
