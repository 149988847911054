import React from 'react';
import './App.css';
import Footer from './components/Footer';

function App() {
  return (
    <main className="root">
      <header>
      </header>
      <div className='content'>
        <div style={{ fontWeight: 600, color: '#000', fontSize: 16 }}>HI THERE </div>
        <div className='welcome'>
          <span>Welcome</span>
          <span>here</span>
        </div>
        <span style={{ fontSize: 60, fontWeight: 100 }}>|</span>
      </div>
      <Footer />
    </main>
  );
}

export default App;
